<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router";
import { computed } from "vue";
import { useForwardProps } from "radix-vue";
import { buttonVariants } from "../Button/button-variants";

type LinkVariant = "filled" | "filledTonal" | "outlined" | "ghost";
type LinkType = "text" | "button" | "icon";

const props = withDefaults(
  defineProps<{
    to: RouteLocationRaw;
    label: string;
    icon?: string;
    type?: LinkType;
    variant?: LinkVariant;
    disabled?: boolean;
    class?: string;
  }>(),
  {
    type: "button" as LinkType,
    variant: "ghost" as LinkVariant,
    disabled: false,
    class: "",
    icon: "", // Add default value for 'icon' prop
  },
);

const delegatedProps = computed(() => {
  const {
    label,
    icon,
    disabled,
    type,
    variant,
    class: _,
    ...delegated
  } = props;
  return delegated;
});

const forwarded = useForwardProps(delegatedProps);

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <NuxtLink
    v-if="type === 'text' || type === 'button'"
    v-bind="{ ...forwarded, ...$attrs }"
    :class="[
      type === 'button'
        ? [
            buttonVariants({
              variant,
              disabled,
            }),
            icon && variant !== 'ghost' && 'pl-4',
          ]
        : 'hover:underline',
      props.class,
    ]"
  >
    <Icon v-if="icon && type === 'button'" :name="icon" class="size-6" />
    <span>{{ label }}</span>
  </NuxtLink>

  <UITooltipProvider v-else-if="type === 'icon'">
    <UITooltip>
      <UITooltipTrigger as-child>
        <NuxtLink
          v-bind="{ ...forwarded, ...$attrs }"
          :class="[
            buttonVariants({
              variant,
              disabled,
            }),
            'size-max shrink-0 p-3',
            props.class,
          ]"
        >
          <Icon v-if="icon" :name="icon" class="size-6" />
        </NuxtLink>
        <span class="sr-only">{{ label }}</span>
      </UITooltipTrigger>

      <UITooltipContent>
        <p>{{ label }}</p>
      </UITooltipContent>
    </UITooltip>
  </UITooltipProvider>
</template>
